@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins";
}
.header-btndiv {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}
.header-btndiv h1{
color:#202620;
font-size: 20px;
line-height: 28px;
font-weight: 600;
}
.header-btndiv button{
  background-color: #FFFFFF;
  border: 1px solid #BE763733;
  color: #BE7637;
  width: 188.5px;
  height: 50px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  line-height: 14.5px;
}

.css-d9ml5t-MuiDrawer-docked .MuiDrawer-paper {
  width: 291px !important;
}
.css-zxtyt4-MuiPaper-root-MuiAppBar-root {
  width: calc(100% - 291px) !important;
  margin-left: 291px !important;
  background-color: unset !important;
  box-shadow: unset !important;
  border-bottom: 1px solid #be763733 !important;
  display: unset !important;
}
.loc-img {
  background-color: #be7637;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
}
.cou-img {
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 5px;
}
.MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root {
  width: 30px !important;
  height: 30px !important;
  background: white;
  max-width: 30px !important;
  border: 1px solid #be76371a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  overflow: hidden;
  padding: 5px;
  min-width: 30px !important;
  box-shadow: 0px 4px 4px #00000012;
  border-radius: 5px;
}

.css-10hburv-MuiTypography-root {
  color: #202620;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.css-1fzmbpm-MuiListItemText-root {
  padding-left: unset !important;
}
#submenu {
  padding-left: 25px;
}
.css-cveggr-MuiListItemIcon-root {
  min-width: 40px !important;
}
.addicon {
  background-color: #be7637;
  border-radius: 50%;
  color: #fff;
}
.arrow {
  color: #be7637;
}

.css-9mgopn-MuiDivider-root {
  border-color: #be763733 !important;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border-right: 1px solid #be763733 !important;
}
img.logoimg {
  width: 150px;
  height: 61.5px;
  margin: 15px 65px;
}
.css-11b3ww9-MuiPaper-root-MuiAppBar-root {
  border: 1px solid #be763733 !important;
  background-color: unset !important;
  box-shadow: unset !important;
}
.top-main {
  display: flex;
  align-items: center;
  gap: 20px;
  right: 45px;
  left: auto;
  position: absolute;
  cursor: pointer;
}

ul.dropdown-menu.bell_drop.show {
  max-height: 450px;
  right: 320px !important;
  top: 16px !important;
  background: rgb(255, 255, 255);
  overflow: auto !important;
  width: 100%;
  max-width: 650px;
}

.Profileimg {
  border-radius: 50%;
  height: 40px;
  box-shadow: 1px 1px 7px #dad1d1;
  width: 40px;
}
.top-midtext {
  line-height: 10px;
  padding-top: 15px;
}
.top-midtext p:first-child {
  color: #202620;
}
.top-midtext p:last-child {
  color: #be7637;
}
.css-hyum1k-MuiToolbar-root {
  min-height: 92px !important;
  background-color: #fff;
}
.logo-buttom {
  border-bottom: 1px solid #be763733 !important;
}

.top-icon
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: #202620;
}
.css-1v5u2yv {
  margin-left: 55px;
}
.step-div {
  width: 50%;
  display: block;
  margin: 35px auto;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #be7637 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: transparent !important;
  border: 1px solid #be763733 !important;
  border-radius: 50px;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active
  .css-117w1su-MuiStepIcon-text {
  fill: #fff;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root .css-117w1su-MuiStepIcon-text {
  fill: #5a5a5a;
}
.css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: #be7637 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  font-size: 3.5rem !important;
}
/* span.MuiStepLabel-root.MuiStepLabel-horizontal.css-ascpo7-MuiStepLabel-root {
  display: flex;
  flex-direction: column;
  gap: 15px;
} */
.css-j5w0w9-MuiStepConnector-root {
  margin-bottom: 20px !important;
}
.activeSubmenuItem
  .color-text
  .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-10hburv-MuiTypography-root {
  color: #be7637;
}
span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-10hburv-MuiTypography-root {
}
.activeMenu
  .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-10hburv-MuiTypography-root {
  color: #be7637;
}
.top-main .dropdown-toggle::after {
  content: none !important;
}

.btn-secondary.dropdown-toggle.top-main.show:hover {
  background: transparent;
}
.dropdown-toggle::after {
  display: none;
}
ul.dropdown-menu.show li {
  /* padding: 10px 20px; */
  border-bottom: 1px solid #be763726;
  display: flex;
  align-items: center;
}
ul.dropdown-menu.show p {
  /* border-bottom: 1px solid #be763726; */
  height: 40px;
  display: block;
  text-align: center;
  align-items: center;
  padding-top: 10px;
}
a.dropdown-item {
  font-size: 13px;
  position: relative;
  font-weight: 500;
  color: #202620;
  white-space: pre-line;
}

.dropdown-item:hover {
  background-color: transparent !important;
  color: #202620;
}
a.dropdown-item::before {
  width: 0px;
  height: 5px;
  content: "";
  background: #be763700;
  border-radius: 30px;
  position: absolute;
  left: 0px;
  top: 15px;
  text-decoration: none;
}
.activeMenu .MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root img {
  filter: brightness(0) saturate(100%) invert(93%) sepia(0%) saturate(7500%)
    hue-rotate(267deg) brightness(107%) contrast(102%);
}

.activeMenu .MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root {
  background: #bd753e;
  border-radius: 4px;
}
#firstmenu .MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root img {
  filter: unset;
}
.MuiList-root.css-1mk9mw3-MuiList-root
  .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters.inactiveSubmenuItem.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root
  .MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root {
  border: none;
  padding: 0 !important;
  box-shadow: none;
}

.activeSubmenuItem .MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root {
  border: none !important;
  box-shadow: none;
}
.Mui-completed span {
  border-color: #D18845 !important;
}
.Mui-active span {
  border-color: #D18845 !important;
}
.css-z7uhs0-MuiStepConnector-line {
  border-top-width: 2px !important;
}
.header-btnicon {
  height: 35px;
  width: 35px;
  border: 1px solid #BE7637;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.header-btnicon svg {
  fill: #BE7637;
}
.header-icondiv h1 {
  color: #202620;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 0px;
}
.header-icondiv {
  display: flex;
  align-items: center;
  gap: 10px;
  /* justify-content: center; */
}
/* Live css*/
header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.mui-fixed.css-1kvaj0b {
  width: calc(100% - 291px) !important;
  margin-left: 291px !important;
  background-color: unset !important;
  box-shadow: unset !important;
  border-bottom: 1px solid #be763733 !important;
}
.css-i6s8oy {
  min-height: 92px !important;
  background-color: #fff !important;
}
.css-1j95noh .MuiDrawer-paper {
  width: 291px !important;
}
.top-icon svg {
  color: #202620;
}
.css-1ni0dl2 {
  padding-left: unset !important;
}
.css-1f8bwsm {
  min-width: 35px !important;
}
.activeMenu
  .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
  color: #be7637;
}
activeSubmenuItem
  .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
  color: #be7637;
}
.activeMenu img {
  filter: brightness(0) saturate(100%) invert(93%) sepia(0%) saturate(7500%)
    hue-rotate(267deg) brightness(107%) contrast(102%);
}
.css-1abj2s5 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column !important;
  gap: 20px !important;
}
.css-4ff9q7.Mui-active {
  color: #be7637 !important;
}
.css-4ff9q7 {
  font-size: 3.5rem !important;
}
span.MuiStepLabel-label.Mui-completed.css-2fdkz6 {
  color: #be7637 !important;
  font-size: 17px;
  line-height: 25.5px;
  font-weight: 600 !important;
}
span.MuiStepLabel-label.Mui-active.css-2fdkz6 {
  color: #5a5a5a !important;
  font-size: 17px;
  line-height: 25.5px;
  font-weight: 600 !important;
}
.css-4ff9q7.Mui-completed {
  color: #be7637 !important;
}
.Mui-disabled .css-4ff9q7 {
  color: transparent !important;
  border: 1px solid #be763733 !important;
  border-radius: 50%;
}
.Mui-disabled text.MuiStepIcon-text.css-10psl7k {
  fill: #5a5a5a;
}
.MuiListItemIcon-root.css-1f8bwsm {
  background-color: #be7637;
  width: 30px !important;
  height: 30px !important;
  background: white;
  max-width: 30px !important;
  border: 1px solid #be76371a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  overflow: hidden;
  padding: 5px;
  min-width: 30px !important;
  box-shadow: 0px 4px 4px #00000012;
  border-radius: 5px;
}
.activeMenu .MuiListItemIcon-root.css-1f8bwsm {
  background: #bd753e;
  border-radius: 4px;
}
span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
  color: #202620;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
}
span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
  color: #202620;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 19.5px;
}
.activeSubmenuItem span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
  color: #be7637 !important;
}
#firstmenu .MuiListItemIcon-root.css-1f8bwsm img {
  filter: unset !important;
}

.make_link::before{
  width: 0px;
  height: 5px;
  content: "";
  background: #be763700;
  border-radius: 30px;
  position: absolute;
  left: 0px;
  top: 15px;
  text-decoration: none;
}
.make_link{
  font-size: 13px;
  position: relative;
  font-weight: 500;
  color: #202620;
  cursor: pointer;
}
.mapstyledesign{
  position: absolute;
  bottom: 18px;
  width: 198px;
}

/*  */
.btn-secondary.dropdown-toggle.top-main.belldiv img {
  width: 26px;
  position: absolute;
  right: 335px;
  top: -16px;
}
p.bell_notification {
  position: absolute;
  color: white;
  font-size: 12px;
  background: red;
  padding: 2px;
  width: 20px;
  height: 20px;
  border-radius: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 325px;
  top: -22px;
}

h4.notification-title {
  color: #202620;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  padding: 12px;
  border-bottom: 1px solid #be763733;
}
