.chat-container {
    display: flex;
    min-height: calc(100vh - 252px);
    background-color: #f1f1f1;
    overflow: hidden;
    position: absolute;
    top: 92px;
    left: 0;
    width: 100%;
}

.chat-list {
    width: 300px;
    max-height: calc(100vh - 95px);
    overflow: auto;
    border-right: 1px solid #ddd;
    background-color: #ffffff;
    padding: 20px 0px;
}

main.MuiBox-root.css-1v5u2yv {

    max-height: calc(100vh - 0px) !important;
    position: relative;
}



.chat-item {
    display: flex;
    padding: 15px;
    cursor: pointer;
    align-items: center;
}

.chat-item:hover {
    background-color: #f0f0f0;
}

.chat-item.unread .chat-name {
    font-weight: bold;
}

.avatar {
    width: 50px;
    height: 50px;
    background-color: #ccc;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
}

.avatar img {
    width: 100%;
    object-fit: cover;
}

.chat-info {
    flex: 1;
}

.chat-name {
    font-weight: 600;
    font-size: medium;
    color: #333;
}

.last-message {
    font-size: small;
    color: #666;
}

.chat-meta {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.time {
    color: #999;
    font-size: 12px;
}

.unread-count {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 8px;
    font-size: 12px;
    margin-top: 5px;
}

.chat-window {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}

.chat-header {
    padding: 20px;
    border-bottom: 1px solid #dddddd87;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #be763714;
    color: #c3753ffc;
}

.chat-title {
    font-size: 18px;
    font-weight: bold;
}

.chat-body {
    padding: 20px;
    overflow-y: auto;
    max-height: calc(100vh - 230px);
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.no-messages {
    max-height: calc(100vh - 100px);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-placeholder {
    max-height: calc(100vh - 100px);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-message {
    margin-bottom: 10px;
}

.message-sender {
    font-size: 12px;
    font-weight: 500;
}

.message-content {
    margin: 5px 0;
}

.message-time {
    color: #666;
    font-size: 12px;
    text-align: end;
}

.message-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #be763714;
}

.message-input input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 4px;
}

.message-input button {
    padding: 10px 20px;
    border: none;
    background-color: #be7637;
    color: white;
    margin-left: 10px;
    border-radius: 4px;
    cursor: pointer;
}



.chat-message.other .message-content {
    max-width: 450px;
    background: #f5f5f5;
    width: 100%;
    padding: 10px;
    border-radius: 10px 10px 10px 0px;
    color: #222;
    font-size: 14px;
    display: inline;
    margin-bottom: 5px;
}

.chat-message.self .message-content {
    float: right;
    text-align: end;
    max-width: 450px;
    background: #c3753f2b;
    width: 100%;
    padding: 10px;
    border-radius: 10px 10px 0px 10px;
    color: #222;
    font-size: 14px;
    display: inline;
    margin-bottom: 5px;

}

input[type="text"]:focus-visible {
    outline: 1px solid #be763769 !important;
}

.chat-message.self {
    text-align: end;
    float: right;
}

.message-meta {
    display: inline-flex;
    flex-direction: column;
}

.chat-box{
    
}