/* :root {
  --main-background-color: red;
  --main-button: #57483b;
} */
.delete-icon {
  background: white;
  width: 28px;
  height: 28px;
  padding: 5.5px;
  border-radius: 5px;
  cursor: pointer;
}

.add-formdiv {
  border: 1px solid #be763733;
  border-radius: 10px;
  margin-bottom: 20px;
  /* border-top-right-radius: 10px;
  border-top-left-radius: 10px; */
}

.logo-title {
  text-align: center;
}

.MuiStep-root.MuiStep-horizontal.css-1bw0nnu-MuiStep-root {
  position: relative;
}

.add-top h2 {
  color: white;
  font-weight: 500;
  font-size: 17px;
  background: #be7637;
  padding: 15px;
  border-radius: 10px 10px 0px 0px;
  text-align: start;
  margin-bottom: 0px;
}

.required-star {
  color: red;
}

.add-form {
  padding: 15px 20px;
}

.form-label {
  color: unset;
  font-size: 14px;
  font-weight: 500;
}

input.form-control {
  height: 50px;
  border-radius: 10px;
  border: 1px solid #be763733;
}

.pro-action {
  justify-content: end;
}

.select-div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 15px;
}

.select-div1,
.select-div2 {
  flex: 1;
  margin-right: 10px;
}

.select-div1:last-child,
.select-div2:last-child {
  margin-right: 0;
}

.form-select {
  height: 50px;
  border-radius: 10px;
  border: 1px solid #be763733;
  margin-bottom: 0px !important;
}

.form-des {
  margin-top: 20px;
}

textarea#floatingTextarea2 {
  border-radius: 10px;
  border: 1px solid #be763733;
  height: 120px;
}

.dis-p {
  margin: 20px 0;
}

.dis-p p {
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 500;
}

.checkbox-details input {
  width: 22px;
  height: 22px;
}

.checkbox-div {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}

.checkbox-details {
  display: flex;
  align-items: center;
}

.checkbox-details input[type="checkbox"] {
  margin-right: 5px;
}

.checkbox-details label {
  color: #5a5a5a;
}

.save-btndiv {
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
}

.add-btn {
  background-color: #be7637;
  border: 1px solid #be7637;
  color: #fff;
  padding: 16px 65px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
}

hr.save-tophr {
  margin: 31px 0;
  border: 1px solid #be763733;
}

.slot-threefield {
  display: flex;
  width: 100%;
  margin-top: 20px;
  gap: 15px;
}

.slot-threefield>div {
  flex: 1;
  margin-right: 10px;
}

.slot-threefield>div:last-child {
  margin-right: 0;
}

.slot-btndiv {
  display: flex;
  justify-content: space-between;
  margin: 30px 0px 0px 0px;
  gap: 10px;
}

.btn-add-slot {
  background-color: transparent;
  border: 1px dotted #be7637;
  color: #be7637;
  padding: 16px 65px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
}

.btn-add-back {
  background-color: transparent;
  border: 1px solid #be7637;
  color: #be7637;
  padding: 16px 50px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
}

.view-slot {
  margin-top: 35px;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: #be7637 !important;
  font-size: 17px;
  line-height: 25.5px;
  font-weight: 600 !important;
}

.MuiStepLabel-label.Mui-disabled.css-1hv8oq8-MuiStepLabel-label {
  color: #5a5a5a !important;
  font-size: 17px;
  line-height: 25.5px;
  font-weight: 600 !important;
}

span.MuiStepLabel-label.Mui-completed.css-1hv8oq8-MuiStepLabel-label {
  color: #5a5a5a !important;
  font-size: 17px;
  line-height: 25.5px;
  font-weight: 600 !important;
}

.add-form table {
  /* border: 1px solid #be763733; */
  margin: 0;
}

.add-form th {
  background-color: #fbf6f1;
  position: relative;
}

.action-icons {
  display: flex;
  gap: 10px;
}

.action-icons i {
  color: #be7637;
}

.action-icons img {
  background: white;
  width: 28px;
  height: 28px;
  padding: 4.5px;
  border-radius: 5px;
  cursor: pointer;
}

.outline-container {
  display: flex;
  gap: 20px;
}

.left-side {
  width: 70%;
}

.left-sidemain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-side {
  width: 30%;
  overflow: scroll;
  height: 100vh;
  position: relative;
}

button.add-btn {
  width: 100%;
  max-width: 215px;
  padding: 10px;
  height: 50px;
}

button.btn-add-back {
  width: 100%;
  max-width: 215px;
  padding: 10px;
  height: 50px;
}

.outline-top {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #be763733;
}

.pro-details {
  display: flex;
  justify-content: space-between;
  padding: 6px 15px 0px 15px;
}

.th-p {
  font-size: 15px;
  font-weight: 500;
  width: 30%;
}

.td-p {
  font-size: 14px;
  font-weight: 400;
  width: 70%;
  text-align: right;
}

.outline-bottom {
  margin: 20px 0;
}

.left-side2 .btn-add-back {
  background-color: transparent;
  border: 1px solid #be7637;
  color: #be7637;
  padding: 12px 20px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  max-width: 215px;
}

.left-side1 select {
  background-color: transparent;
  border: 1px solid #be7637;
  color: #be7637;
  padding: 12px 12px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  width: 639px;
}

.map-div iframe {
  width: 100%;
  height: 813px;
  border-radius: 10px;
  margin: 20px 0;
}

.map-div {
  margin: 20px 0;
}

.calculation-box {
  height: 75px;
  width: 150px;
  position: absolute;
  bottom: 40px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
}

.slot-prodiv {
  margin: unset;
}

.css-zxtyt4-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
}

.btn-slot-pro {
  background-color: transparent;
  border: 1px dotted #be7637;
  color: #be7637;
  padding: 16px 65px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
}

.add-form th {
  font-size: 14px !important;
  color: #202620 !important;
  font-weight: 500 !important;
}

.add-form td {
  font-size: 14px !important;
  color: #5a5a5a !important;
  font-weight: 400 !important;
}

.slot-datetable {
  display: flex;
  justify-content: space-between;
  background-color: #be7637;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
}

.slot-datetable1 h2 {
  background-color: unset;
  border-radius: unset;
}

.slot-datetable2 {
  display: flex;
  gap: 10px;
  margin-right: 15px;
}

.slot-datetable2 img {
  background: white;
  width: 28px;
  height: 28px;
  padding: 4.5px;
  border-radius: 5px;
  cursor: pointer;
}

.slot-datetable2 i {
  background: #fff;
  padding: 8px 8px;
  border-radius: 5px;
  color: #be7637;
}

.slot-datetable2 img {
  background: #fff;
  padding: 8px 8px;
  border-radius: 5px;
  color: #be7637;
}

canvas.mapboxgl-canvas {
  border-radius: 10px;
}

.add-formdiv-pro {
  border: 1px solid #be763733;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.pro-review {
  margin-top: 20px;
  margin-bottom: unset;
}

.manage-Qicon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.pro-review .save-btn {
  width: 95px;
  height: 56px;
  background-color: #be7637;
  border: 1px solid #be7637;
  color: #fff;
  padding: 16px 65px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pro-review-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #be763733;
  padding-bottom: 20px;
}

.pro-review-tops {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
}

.btn-pro-review {
  background-color: #be7637;
  border: 1px solid #be7637;
  color: #fff;
  padding: 15px 20px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  line-height: 14.5px;
}

.btn-pro-review i {
  margin-right: 10px;
}

.pro-review-ftop h1 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #be7637;
}

.pro-hr {
  margin-top: 20px;
  padding-bottom: 30px;
}

.areas\&slot-div {
  margin-top: 20px;
}

.areas\&slot-buttion {
  display: flex;
  gap: 15px;
}

.areas\&slot-buttion .button1 {
  background-color: #be7637;
  border: 1px solid #be7637;
  color: #fff;
  padding: 16px 50px;
  font-size: 15px;
  font-weight: 600;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.areas\&slot-buttion .button2 {
  background-color: transparent;
  border: 1px solid #be763733;
  color: #202620;
  padding: 16px 50px;
  font-size: 15px;
  font-weight: 500;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.pro-upload {
  margin-top: 20px;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-completed.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: #be7637 !important;
}

.upload-container {
  border: 2px dotted #be7637;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  border-style: dashed;
  height: 274px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-input {
  display: none;
}

.upload-button {
  margin-top: 10px;
  cursor: pointer;
}

.upload-div p {
  font-size: 14px;
  color: #202620;
  font-weight: 600;
  line-height: 21px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.upload-div span {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

button.upload-button {
  border: 1px solid #be763733;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 8px;
  background-color: transparent;
}

.table> :not(caption)>*>* {
  padding: 0.7rem 1.4rem;
}

.areas\&slot-div .add-table {
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
}

.manage-editbtn {
  background-color: #f6e7da;
  border: 1px solid #f6e7da;
  color: #be7637;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.manage-editbtn i {
  margin-right: 8px;
}

.manage-editbtn {
  background-color: #f6e7da;
  border: 1px solid #f6e7da;
  color: #be7637;
  padding: 6px 18px;
  border-radius: 16px;
  width: 100px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.manage-editbtn i {
  margin-right: 8px;
}

.manage-deletebtn {
  background-color: #ffcccc;
  border: 1px solid #ffcccc;
  color: #f8285a;
  padding: 6px 18px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.manage-deletebtn i {
  margin-right: 8px;
  color: #f8285a;
}

.createcoupon-checkbox input[type="checkbox"] {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  margin-top: 5px;
}

.add-form a {
  font-size: 14px !important;
  color: #5a5a5a !important;
  font-weight: 400 !important;
  text-decoration: underline;
}

.hunter-popup .popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hunter-popup .popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  height: 383px;
  width: 591px;
}

.hunter-popup i.fa-solid.fa-xmark {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  height: 35px;
  font-size: 20px;
  color: #5a5a5a;
}

.hunter-popup textarea {
  resize: vertical;
  width: 545px;
  height: 123px;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #be7637;
  background-color: #fdfbf9;
  resize: none;
}

.hunter-popup h2 {
  color: #202620;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  margin-top: 25px;
}

.start-div {
  display: flex;
  justify-content: center;
  margin: 25px 0px 20px 0px;
}

.hunter-btndiv {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.hunter-btndiv .cancel {
  background-color: transparent;
  border: 1px solid #be7637;
  color: #be7637;
  padding: 16px 110px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
}

.hunter-btndiv .post {
  background-color: #be7637;
  border: 1px solid #be7637;
  color: #fff;
  padding: 16px 110px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
}

.search-div {
  display: flex;
  justify-content: end;
  gap: 15px;
  margin: 10px 20px 10px 0px;
}

.hunter-serch {
  /* max-width: 450px;
  font-size: 14px;
  font-weight: 400; */
}

.search-div .add-btn {
  padding: 12px 65px;
  background-color: #be7637;
  color: #ffffff;
}

.real-main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.cursor-pointer {
  cursor: pointer;
}

.real-section {
  flex: 1;
  margin-right: 25px;
  border: 1px solid #be763733;
  border-radius: 10px;
  position: relative;
  margin-bottom: 25px;
}

.manage-actiondiv {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: end;
  width: 100%;
  gap: 10px;
  position: absolute;
  top: 0;
}

.manage-actiondiv img {
  background: white;
  width: 28px;
  height: 28px;
  padding: 5.5px;
  border-radius: 5px;
  cursor: pointer;
}

.real-img {
  width: 100%;
  height: 291.5px;
  border-radius: 8px 8px 0px 0px;
}

.real-section h1 {
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
}

.real-subsection {
  padding: 20px 20px 10px 20px;
}

.realicon-p {
  padding-top: 2px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}

.realicon-p i {
  font-size: 18px;
  color: #be7637;
}

.realicon-p p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21.6px;
  color: #be7637;
  margin-bottom: 2px;
}

.realicon-p svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  font-size: 18px;
  color: #be7637;
}

.reservation-btn {
  display: flex;
  gap: 15px;
}

.reservation-btn .active-button {
  background-color: #be7637;
  border: 1px solid #be7637;
  color: #fff;
  padding: 16px 50px;
  font-size: 15px;
  font-weight: 600;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.reservation-btn .deactive-button {
  background-color: transparent;
  border: 1px solid #be763733;
  color: #202620;
  padding: 16px 50px;
  font-size: 15px;
  font-weight: 500;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.reservation-table {
  padding: unset;
}

.reservation-table.add-form .add-table {
  border-radius: 0px 10px 0px 0px;
}

.reservation-table td:nth-child(6) {
  color: #be7637 !important;
}

.reservation-actionbtn i {
  margin-right: 6px;
}

.reservation-actionbtns {
  min-width: 115px;
}

.reservation-actionbtns i {
  margin-right: 6px;
}

.upcoming_cancel .reservation-actionbtn {
  background-color: #f6e7da;
  border: 1px solid #f6e7da;
  color: #be7637;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.upcoming_cancelled .reservation-actionbtn {
  background-color: #ffcccc;
  border: 1px solid #ffcccc;
  color: #f8285a;
  padding: 6px 12px;
  border-radius: 16px;
  cursor: default;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.upcoming_pending .reservation-actionbtn {
  background-color: #e4e5e4;
  border: 1px solid #e4e5e4;
  color: #5a5a5a;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.upcoming_pending .reservation-actionbtns {
  background-color: #fcc;
  border: 1px solid #fcc;
  color: #f8285a;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.upcoming_booked .reservation-actionbtns {
  background-color: #fcc;
  border: 1px solid #fcc;
  color: #f8285a;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.upcoming_cancelled .reservation-actionbtns {
  background-color: #fcc;
  border: 1px solid #fcc;
  color: #f8285a;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  cursor: default;
  line-height: 18px;
}

.upcoming_booked .reservation-actionbtn {
  background-color: #d1f4dd;
  border: 1px solid #d1f4dd;
  color: #17c653;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  cursor: default;
  font-weight: 500;
  line-height: 18px;
}

.history_pending .reservation-actionbtn {
  background-color: #e4e5e4;
  border: 1px solid #e4e5e4;
  color: #5a5a5a;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.history_cancelled .reservation-actionbtn {
  background-color: #fcc;
  border: 1px solid #fcc;
  color: #f8285a;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  cursor: default;
  font-weight: 500;
  line-height: 18px;
}

.history_booked .reservation-actionbtn {
  background-color: #d1f4dd;
  border: 1px solid #d1f4dd;
  color: #17c653;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  cursor: default;
  font-weight: 500;
  line-height: 18px;
}

.history_completed .reservation-actionbtn {
  background-color: #d1f4dd;
  border: 1px solid #d1f4dd;
  color: #17c653;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  cursor: default;
  font-weight: 500;
  line-height: 18px;
}

.reservation-idtable .add-form {
  padding: unset;
}

.reservation_divider {
  display: flex;
  justify-content: space-between;
  height: 111px;
  margin: 25px 0;
}

.reservation_part {
  width: calc(25%);
  border: 1px solid #be763733;
  background-color: #fbf6f1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reservation_part:first-child {
  border-right: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.reservation_part:nth-child(2) {
  border-right: none !important;
}

.reservation_part:last-child {
  border-left: none !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.reservation_content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 5px;
}

.reservation_content h1 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #be7637;
}

.reservation_content p {
  font-size: 17px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 0px;
}

.reservation-table a {
  font-size: 14px !important;
  color: #5a5a5a !important;
  font-weight: 400 !important;
  text-decoration: underline;
}

.checkbox-details input {
  box-shadow: inset 1px 2px 20px #be763733;
  border: 1px solid red !important;
}

.checkbox-details input:checked {
  accent-color: #be7637;
}

.add-table {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #bd753e30;
  margin: 0;
  padding: 0;
}

.css-8atqhb {
  width: 100%;
  max-width: 670px;
  margin: auto;
}

.managecoupons-table {
  padding: unset;
}

.managecoupons-table td:nth-child(3) {}

.managecoupons-table .add-table {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  border-bottom: none !important;
}

/* =========================== pagination ==================== */

.table> :not(caption)>*>* {
  padding: 14px 15px;
  height: 48px;
  border-color: #be763726;
}

ul.pagination {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 10px 15px;
  gap: 10px;
  border: 1px solid #be763726;
  border-radius: 0px 0px 10px 10px;
  border-top: none;
}

li.active {
  height: 36px;
  width: 36px;
  padding: 15px;
  border: 1px solid #be7637 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #d188451a !important;
  color: #be7637;
}

li.previous {
  width: auto !important;
  border: none !important;
  padding: 0;
}

li.previous.disabled {
  width: auto;
  border: none;
  padding: 0;
}

li.next {
  width: auto;
  border: none !important;
  padding: 0 !important;
}

li.active a {
  color: #be7637 !important;
}

ul.pagination a {
  text-decoration: none;
  font-size: 14px !important;
  color: #5a5a5a !important;
  font-weight: 400 !important;
}

ul.pagination li {
  height: 36px;
  width: 36px;
  padding: 15px;
  border: 1px solid #be763726;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: white;
}

.add-form.pagination-table .add-table {
  border-bottom: none !important;
  border-radius: 10px 10px 0px 0px;
}

.proper-add {
  background-color: #be7637;
  border: 1px solid #be7637;
  color: #fff;
  height: 56px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  line-height: 14.5px;
  padding: 5px 10px;
}

button.proper-add.proper-add-slot {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px 10px;
}

.proper-adddiv {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}

.pro-review-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pro-review-content {
  background-color: white;
  padding: 0px 0px 20px 0px;
  border-radius: 10px;
  position: relative;
  align-items: center;
  width: 60%;
}

.slot-top i.fa-solid.fa-xmark {
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  width: 20px;
  height: 20px;
}

.slot-top {
  display: flex;
  justify-content: space-between;
  background-color: #be7637;
  border-radius: 8px 8px 0px 0px;
  align-items: center;
  color: #fff;
  height: 50px;
  padding: 0px 20px;
}

.slot-top h1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 29.16px;
  margin-bottom: 0;
}

.pro-review-popupcontent .select-div>div {
  flex: 1;
  margin-right: 10px;
}

.pro-review-popupcontent .select-div {
  display: flex;
  margin-right: 60px;
}

.pro-review-popupcontent .add-form {
  padding: 10px 20px;
}

.slotdelet-div {
  background-color: #be7637;
}

.slotion-main {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.slotdelet-div {
  background-color: #be7637;
  height: 50px;
  width: 48px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 45px;
}

.slot-backdiv {
  display: flex;
  align-items: center;
  gap: 10px;
}

.slot-backdiv .back-btn {
  background-color: transparent;
  border: 1px solid #be7637;
  color: #be7637;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  height: 50px;
  width: 195.01px;
}

.slot-backdiv .add-btn {
  width: 195.01px;
}

.managecou-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.managecou-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 450px;
  height: 359px;
}

.managecou-contentdiv {
  text-align: center;
  margin: 20px 0;
}

.managecou-content .fa-xmark {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #5a5a5a;
  width: 20px;
  height: 20px;
}

.managecou-contentdiv img {
  width: 78px;
  height: 78px;
  margin-bottom: 20px;
}

.managecou-contentdiv h1 {
  color: #202620;
  font-size: 22px;
  font-weight: 600;
}

.managecou-contentdiv p {
  color: #5a5a5a;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin: 10px 60px 25px 60px;
}

.managecou-btn {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.managecou-btn .btn1 {
  width: 190px;
  padding: 15px 78px;
  border-radius: 10px;
  border: 1px solid #f15e5e;
  background: #fff;
  color: #f15e5e;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.managecou-btn .btn2 {
  width: 190px;
  padding: 15px 78px;
  border-radius: 10px;
  border: 1px solid #f15e5e;
  background: #f15e5e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.mapboxgl-ctrl-attrib-button {
  display: none;
}

.step-div-map-edit a {
  color: #5a5a5a !important;
}

.step-div-map-edit .MuiStepLabel-iconContainer {
  display: none !important;
}

.step-div-map-edit .css-j5w0w9-MuiStepConnector-root {
  margin-bottom: 40px !important;
}

.step-div-map-edit .css-1vyamtt-MuiStepLabel-labelContainer {
  position: inherit;
  white-space: inherit;
  display: inherit;
  transform: inherit;
  top: inherit;
  width: inherit !important;
}

.step-div-map-edit span.MuiStepLabel-root.MuiStepLabel-horizontal.css-ascpo7-MuiStepLabel-root {
  margin-bottom: 40px;
}

.step-div-map-edit span.MuiStepLabel-labelContainer.css-h2cmlr {
  display: inherit;
  position: inherit;
}

span.MuiStepLabel-root.MuiStepLabel-horizontal.css-ascpo7-MuiStepLabel-root {
  /* position: relative; */
  margin-bottom: 20px;
}

.css-1vyamtt-MuiStepLabel-labelContainer {
  position: absolute;
  white-space: nowrap;
  left: 0;
  display: inline-block;
  transform: translateX(-35%);
  top: 65px;
  width: 250px !important;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
}

.step-div .css-vnkopk-MuiStepLabel-iconContainer {
  padding-right: 0 !important;
}

.css-j5w0w9-MuiStepConnector-root {
  margin-bottom: 20px !important;
}

.manage-actionmain .ribbon {
  position: absolute;
  display: block;
  left: 0;
  top: 20px;
  width: auto;
  background-color: #be7637;
  padding: 3px 14px;
  color: #fff;
  font-size: 10px;
  min-height: 22px;
  font-weight: 500;
  line-height: 15px;
}

.manage-actionmain .ribbon::before {
  content: "";
  border-bottom: 11px solid transparent;
  border-left: 11px solid #be7637;
  display: inline-block;
  position: absolute;
  right: -10px;
  top: 0;
}

.manage-actionmain .ribbon::after {
  content: "";
  border-top: 11px solid transparent;
  border-left: 11px solid #be7637;
  display: inline-block;
  position: absolute;
  right: -10px;
  bottom: 0;
}

.manage-actionmain .ribbon-green {
  position: absolute;
  display: block;
  left: 0;
  top: 20px;
  width: auto;
  background-color: #37be4b;
  padding: 3px 14px;
  color: #fff;
  font-size: 10px;
  min-height: 22px;
  font-weight: 500;
  line-height: 15px;
}

.manage-actionmain .ribbon-green::before {
  content: "";
  border-bottom: 11px solid transparent;
  border-left: 11px solid #37be4b;
  display: inline-block;
  position: absolute;
  right: -10px;
  top: 0;
}

.manage-actionmain .ribbon-green::after {
  content: "";
  border-top: 11px solid transparent;
  border-left: 11px solid #37be4b;
  display: inline-block;
  position: absolute;
  right: -10px;
  bottom: 0;
}

.manage-actionmain .ribbon-red {
  position: absolute;
  display: block;
  left: 0;
  top: 20px;
  width: auto;
  background-color: #f8285a;
  padding: 3px 14px;
  color: #fff;
  font-size: 10px;
  min-height: 22px;
  font-weight: 500;
  line-height: 15px;
}

.manage-actionmain .ribbon-red::before {
  content: "";
  border-bottom: 11px solid transparent;
  border-left: 11px solid #f8285a;
  display: inline-block;
  position: absolute;
  right: -10px;
  top: 0;
}

.manage-actionmain .ribbon-red::after {
  content: "";
  border-top: 11px solid transparent;
  border-left: 11px solid #f8285a;
  display: inline-block;
  position: absolute;
  right: -10px;
  bottom: 0;
}

.label-cancel{
  position: relative;
}
.label-cancel .ribbon-red {
  position: absolute;
  display: block;
  left: 50px;
  top: 0;
  width: auto;
  background-color: #ffcccc;
  padding: 3px 14px;
  color: #f8285a;
  font-size: 10px;
  min-height: 22px;
  font-weight: 500;
  line-height: 15px;
}

.label-cancel .ribbon-red::before {
  content: "";
  border-bottom: 11px solid transparent;
  border-left: 11px solid #ffcccc;
  display: inline-block;
  position: absolute;
  right: -10px;
  top: 0;
}

.label-cancel .ribbon-red::after {
  content: "";
  border-top: 11px solid transparent;
  border-left: 11px solid #ffcccc;
  display: inline-block;
  position: absolute;
  right: -10px;
  bottom: 0;
}

.createcoupon-checkbox input {
  box-shadow: inset 1px 2px 20px #be763733;
}

.createcoupon-checkbox input:checked {
  accent-color: #be7637;
}

.prodetails-btn {
  justify-content: center !important;
}

.res-delete {
  height: 398px;
}

.res-delete .managecou-contentdiv p {
  margin: 10px 60px 10px 60px;
}

.res-delete textarea#floatingTextarea2 {
  height: 72px;
  border: 1px solid #828282;
  border-radius: 4px;
}

.realdetails-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.realdetails-content {
  background-color: white;
  padding: 0px 0px 20px 0px;
  border-radius: 10px;
  position: relative;
  align-items: center;
  width: 552px;
  height: 445px;
}

.realdetails-content-all {
  background-color: white;
  padding: 0px 0px 20px 0px;
  border-radius: 10px;
  position: relative;
  align-items: center;
  width: 552px;
  height: 285px;
}

.realdetails-top {
  display: flex;
  justify-content: space-between;
  background-color: #be7637;
  border-radius: 8px 8px 0px 0px;
  align-items: center;
  color: #fff;
  height: 44px;
  padding: 0px 20px;
}

.realdetails-top i.fa-solid.fa-xmark {
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  width: 20px;
  height: 20px;
}

.realdetails-top h1 {
  font-size: 15px;
  font-weight: 600;
  line-height: 22.5px;
  margin-bottom: 0;
}

.realdetails-contentmain {
  background-color: #d188450f;
  padding: 10px 20px 10px 20px;
}

.realdetails-contentdiv1 {
  display: flex;
  justify-content: space-between;
}

.realdetails-contentdiv1 .th-p {
  font-size: 13px;
  font-weight: 500;
  width: 50%;
  line-height: 19.5px;
  color: #202620;
  margin-bottom: 12px;
}

.realdetails-contentdiv1 .td-p {
  font-size: 13px;
  font-weight: 400;
  width: 50%;
  text-align: left;
  line-height: 19.5px;
  color: #5a5a5a;
  margin-bottom: 12px;
}

.real-areadiv {
  padding: 10px 20px 10px 20px;
}

.real-areadiv .form-label {
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
}

.real-areadiv .form-floating>label {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.real-areadiv textarea#floatingTextarea2 {
  border-radius: 10px;
  border: 1px solid #be763733;
  max-height: 186px !important;
}

.realsave-btndiv {
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
}

.realsave-btndiv .add-btn {
  width: 79px;
  height: 40px;
  margin-right: 20px;
}

.areacolor-div {
  display: flex;
  gap: 15px;
  align-items: center;
  border: 1px solid #be763733;
  border-radius: 10px;
  padding: 0 10px;
  height: 50px;
}

.areacolor-div input {
  height: 40px;
  width: 34.5px;
  border-radius: 10px !important;
  border: unset !important;
  box-shadow: unset !important;
  overflow: hidden;
  transform: scale(1.5);
}

.areacolor-div p {
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 400;
  line-height: 14.5px;
  margin-bottom: 0;
}

.Mui-completed span {
  border-color: #d18845 !important;
}

.Mui-active span {
  border-color: #d18845 !important;
}

.css-z7uhs0-MuiStepConnector-line {
  border-top-width: 2px !important;
}

.orange {
  color: #ffa500;
}

.grey {
  color: none;
}

.upload-div .image-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  position: relative;
}

.upload-div .image-wrapper {
  position: relative;
  margin-right: 20px;
}

.upload-div .image {
  width: 90px;
  height: 90px;
  object-fit: cover;
  margin-bottom: 5px;
}

.upload-div .remove-icon {
  position: absolute;
  top: -9px;
  cursor: pointer;
  background-color: #be7637;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
  z-index: 1;
  font-size: 20px;
  left: 80px;
}

.areacolor-input {
  height: 40px;
  width: 34.5px;
  border-radius: 10px !important;
  overflow: hidden;
  border: 1px solid #ddd;
}

.header-btndiv1 {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 15px;
}

.header-btndiv1 button {
  background-color: #be7637;
  border: 1px solid #be763733;
  color: #ffffff;
  width: 188.5px;
  height: 50px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  line-height: 14.5px;
}


/* Live */

.MuiStepLabel-labelContainer css-h2cmlr {
  position: absolute;
  white-space: nowrap;
  left: 0;
  display: inline-block;
  transform: translateX(-35%);
  top: 65px;
  width: 250px !important;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
}

span.MuiStepLabel-label.Mui-disabled.css-2fdkz6 {
  color: #5a5a5a !important;
  font-size: 17px;
  line-height: 25.5px;
  font-weight: 600 !important;
}

span.MuiStepLabel-root.MuiStepLabel-horizontal.css-1abj2s5 {
  position: relative;
  margin-bottom: 40px;
}

.MuiStepConnector-root.MuiStepConnector-horizontal.Mui-completed.css-11qjisw {
  margin-bottom: 40px;
}

.MuiStepConnector-root.MuiStepConnector-horizontal.Mui-disabled.css-11qjisw {
  margin-bottom: 40px;
}

.MuiStepConnector-root.MuiStepConnector-horizontal.Mui-active.css-11qjisw {
  margin-bottom: 40px;
}

.css-95m0ql {
  border-top-width: 2px;
}

.step-div-add-property span.MuiStepLabel-labelContainer.css-h2cmlr {
  position: absolute;
  white-space: nowrap;
  left: 0;
  display: inline-block;
  transform: translateX(-35%);
  top: 65px;
  width: 250px !important;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
}

span.MuiStepConnector-line.MuiStepConnector-lineHorizontal.css-95m0ql {
  border-top-width: 2px !important;
}

.colorselecthandel {
  display: flex;
  border: 1px solid #D18845;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  border-radius: 10px;
  align-items: center;
  padding: 0 10px;
  height: 48px;
}

.colorselecthandel .colorselect {
  height: 20px;
  width: 20px;
  border-radius: 50%;

}

.left-side1.choose-left {
  width: 50%;
  height: 48px;
  display: flex;
  align-items: center;
}

.choosecolor-div {
  display: flex;
  gap: 4px;
  align-items: center;
  border: 1px solid #BE7637;
  border-radius: 4px;
  padding: 0px 3px;
  height: 35px;
  width: 52px;
  background-color: transparent !important;
}

.choosecolor-input {
  height: 20px;
  width: 20px;
  border-radius: 50% !important;
  overflow: hidden;
  border: 1px solid #ddd;
}

.choosecolor-div input {
  height: 20px;
  width: 20px;
  border-radius: 50% !important;
  border: unset !important;
  box-shadow: unset !important;
  overflow: hidden;
  transform: scale(1.5);
}

.choosecolor-div i {
  color: #BE7637;
}

.colorselecthandel_1 h1 {
  font-size: 17px;
  font-weight: 600;
  color: #BE7637;
  margin-bottom: 0;
}

.colorselecthandel_2 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.header-empty {
  text-align: center;
  max-width: 100%;
  width: 100%;
  position: relative;
  margin-top: 20px;
}

.form-control:disabled {
  background-color: #fff;
}

.map-div {
  position: relative;
}

.react-mapbox-ac-input {
  position: absolute;
  top: 10px;
  width: 40em;
  right: 10px;
}

.react-mapbox-ac-menu {
  top: 80px;
  right: 10px;
}

.mapboxgl-control-container {
  position: absolute;
  top: 10px;
  left: 10px;
}

.mapboxgl-ctrl-attrib-inner {
  display: none;
}

.property-popup {
  position: fixed;
  left: calc(50% - 200px);
  top: 460px;
  width: 300px;
  /* height: 290px; */
  background-color: transparent;
  z-index: 9999;
  display: flex;
  justify-content: left;
  align-items: center;
}

.property-content {
  background-color: white;
  padding: 0px 0px 20px 0px;
  border-radius: 10px;
  position: relative;
  align-items: center;
  width: 300px;
  height: 400px;
  box-shadow: 1px 1px 7px #989393;
}

.property-content-inner {
  overflow: scroll;
  height: 270px;
}

.property-top {
  display: flex;
  justify-content: space-between;
  background-color: #be7637;
  border-radius: 8px 8px 0px 0px;
  align-items: center;
  color: #fff;
  height: 50px;
  padding: 0px 20px;
}

.property-top h1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 29.16px;
  margin-bottom: 0;
}

.propery-detailsdiv {
  padding: 10px;
}

.propery-detailsdiv {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #be763733;
}

.propery-detailsdiv .p1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #5A5A5A;
}

.propery-detailsdiv .p2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #BE7637;
}

.img-div img {
  width: 17px;
  height: 16px;
  object-fit: contain;
  gap: 10px;
  cursor: pointer;
}

.img-div img:last-child {
  margin-left: 15px;
}

.property-btn {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 0 !important;
  gap: 10px;
}

.property-btn .btn1 {
  background-color: transparent;
  border: 1px solid #be7637;
  color: #be7637;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  height: 44px;
  width: 136.79px;
}

.property-btn .btn2 {
  background-color: #be7637;
  border: 1px solid #be7637;
  color: #fff;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  height: 44px;
  width: 136.79px;
  margin: 0 auto;
}

.map_style {
  position: absolute;
  bottom: 20px;
  display: flex;
  gap: 10px;
  background: white;
  padding: 10px;
  left: 20px;
  border-radius: 4px;
  height: 46px;
  box-shadow: 0px 0px 10px #be763721;
}

.map_style input {
  display: none;
}

.map_style .form-check {
  min-height: auto !important;
  padding: 0 !important;
}

.form-check-input[type=radio]:checked+label {
  color: #be7637 !important;
}

.map_style::before {
  content: "";
  position: absolute;
  height: 46px;
  width: 1px;
  background: #be763733;
  top: 0;
  left: 52px;
}

label.form-check-label {
  font-size: 15px;
}

.mapboxgl-ctrl-group.mapboxgl-ctrl {
  background: white;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  border-radius: 2px;
}

.mapboxgl-ctrl-group.mapboxgl-ctrl button {
  border: none;
  background-color: transparent;
}

.slotdelet-div {
  cursor: pointer;
}

.ellipses {
  /* white-space: nowrap; */
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-city {
  position: relative;
}

.select-div1 .react-mapbox-ac-menu {
  position: relative;
  top: 0;
}

.suggestion-list {
  padding: 8px 10px;
  cursor: pointer;
}

.suggestion-list:hover {
  background: #be7637;
  color: white;
}

.suggestion-list-container {
  box-shadow: 0px 0px 10px #ddddddd0;
  border: 1px solid #be763733;
  z-index: 999;
  border-radius: 4px;
  position: absolute;
  max-width: 745px;
  background: white;
}

.property-container {
  z-index: 1;
}

.image-section {
  width: 100%;
  height: 291.5px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.danger-color {
  color: #f8285a !important;
}

.dangerbadge {
  background: red;
  border-radius: 10px;
  padding: 5px;
  color: #fff;
}
.loadmore-btn .loader{
  height: 25px;
  width: 25px;
}
.loadmore-btn button {
  width: 100%;
  max-width: 215px;
  background-color: #be7637;
  border: 1px solid #be7637;
  border-radius: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 0 12px 0;
  display: block;
  margin: 0 auto;
  line-height: 15px;
}

.loadmore-btn {
  width: 100%;
  padding-bottom: 50px;
}


.clear-btn {
  background: none;
  border: none;
  color: #aaa;
  cursor: pointer;
  font-size: 1.5em;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.header-btndiv1 .clear-btn {
  background: none !important;
  border: none !important;
  color: #aaa !important;
  cursor: pointer;
  font-size: 1.4em;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  line-height: normal !important;
}

.input-wrapper {
  position: relative;
  width: 30%;
}

.coupon-wrapper {
  position: relative;
  width: 65%;
  display: inline-block;
}

.loaders {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #666666;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  margin: auto;
  margin-top: 0px;
}
.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #666666;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin: auto;
  margin-top: 0px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.proper-add-slot {
  float: right;
  height: 40px !important;
}

.edit-area-pencil {
  background: transparent !important;
  height: 40px !important;
  padding: 0 !important;
}

.step-div-map-edit {
  margin-top: 30px;
}

.arrow_close {
  cursor: pointer;
  height: auto;
}

.arrow_open {
  transform: rotate(180deg);
  cursor: pointer;
  height: auto;
}













.chat-list {
  width: 300px;
  background-color: #f1f1f1;
  overflow-y: auto;
  border-right: 1px solid #ddd;
}

.chat-item {
  display: flex;
  padding: 15px;
  cursor: pointer;
  align-items: center;
}

.chat-item:hover {
  background-color: #ddd;
}

.unread {
  font-weight: bold;
}

.avatar {
  width: 50px;
  height: 50px;
  background-color: #aaa;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-info {
  flex: 1;
}

.chat-name {
  font-weight: bold;
}

.last-message {
  color: #666;
}

.chat-meta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.time {
  color: #999;
}

.unread-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 8px;
  font-size: 12px;
  margin-top: 5px;
}

tr.upcoming_booked td:nth-child(5) {
  min-width: 80px;
}

tr.upcoming_booked td:nth-child(4) {
  min-width: 80px;
}








.total-amount {
  text-align: right;
  font-size: 18px;
  font-weight: 600;
}

.total-amount p {
  margin: 0;
}


.refund-amount {
  text-align: right;
  font-size: 14px;
  font-weight: 400;
}

.refund-amount p {
  margin: 0;
}

.cancel-slot-button {
  padding: 5px 10px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  font-size: 14px;
}
.cancel-slot-button-cancel{
  background-color: #ffcccc;
  border: 1px solid #ffcccc;
  color: rgb(247, 60, 103);
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  cursor: default;
  font-weight: 500;
  line-height: 18px;
}
.cancel-slot-button:hover {
  background-color: #ff3333;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

.cancel-button,
.other-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.cancel-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
}

.other-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
}



.input-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.input-section p {
  font-size: 14px;
  margin: 0;
}

.input-section input {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 150px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;

  border: 1px solid #be763733;
  border-radius: 10px;
  height: 44px;

}

.refund-action-button:hover {
  background-color: #be7637 !important;

}




.refund-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.refund-action-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #be7637;
  border: 1px solid #be7637;
  border-radius: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  width: 100%;


}

.refund-action-button:hover {
  background-color: #0056b3;
  margin-bottom: 30px;
}

/* ==================== */
.Reservation_details_table th:first-child {
  text-align: start;
}

.Reservation_details_table td:first-child {
  text-align: start;
}


.Reservation_details_table th:nth-child(2) {
  text-align: start;
}

.Reservation_details_table td:nth-child(2) {
  text-align: start;
}

.Reservation_details_table th:nth-child(4) {
  text-align: center;

}

.Reservation_details_table td:nth-child(4) {
  text-align: center;

}


.resv_section_grid {
  display: grid;
  /* grid-template-columns: 1fr 450px; */
  align-items: flex-start;
  gap: 20px;
  margin: 20px 0px;
  padding-bottom: 40px;
}

.total-amount.text-start p {
  display: flex;
  justify-content: space-between;
}


.refund-amount.text-start p {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.profile-top h1 {
  margin-bottom: 0px;
}

.side_cart_col {
  border: 1px solid #bd753e30;
  padding: 20px;
  border-radius: 10px;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 0 auto; /* Center the loader */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.header-btnicon{
  cursor: pointer;
}


.rowperpage {
  display: inline-flex;
  justify-content: space-between;
  text-decoration: none;
  font-size: 14px !important;
  color: #5a5a5a !important;
  font-weight: 400 !important;
  width: 100%;
  border: 1px solid #be763726;
  border-radius: 0px 0px 10px 10px;
  border-top: none;
  padding: 10px 20px;
  align-items: center;
  margin-bottom: 30px;
}
.rowperpage .custom-pagination{
  border: none !important;
  height: auto !important;
  margin: 0 !important;
}

.perpagecount select {
  width: auto;
  padding: 6px;
  border: 1px solid #be763726;
  border-radius: 4PX;
  color: #5A5A5A;
}


.loader_section {
  position: relative;
  z-index: 99;
}
.res_loader {
  position: fixed;
  top: 0;
  right: 0;
  width: 90%;
  height: 100vh;
  align-content: center;
  background: #ffffff8f;
  backdrop-filter: blur(1px);
}
.res_loader  .spinner-border.text-primary {
  position: absolute;
  right: 0;
  margin: auto;
  left: 0;
  width: 50px;
  height: 50px;
  border-width: 5px !important;
}

.reservation-table.add-form .add-table th {
  min-width: 175px;
}
main.MuiBox-root.css-1v5u2yv {
  max-width: calc(100% - 300px);
}
.spacing-top{
  margin-top: 15em;
}
.spacing-top-propertydetail{
  margin-top: 15em;
  position: fixed;
  top: 2em;
  left: 50%;
}
.loader-top-spacing{
  margin-top: 20em;
}


.add-table::-webkit-scrollbar {
  width: 8px;
  height: 8px;

}

/* Handle */
.add-table::-webkit-scrollbar-thumb {
  background: #be76379c !important; 
}

 .add-table {
  overflow: auto;
  max-height: calc(100vh - 300px);
}

.add-table.upcoming_reservation_table , .add-table.history_reservation_table {
  max-height: calc(100vh - 360px);
}

.pro-link{
  text-decoration: underline;
  cursor: pointer;
}