@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.col-lg-7.col-sm-12 {
  position: relative;
}

.login_section {
  max-width: 100%;
  margin: 0px auto;
  min-height: calc(100vh - 0px);
  position: relative;
  max-height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.image_section img {
  width: 100%;
  max-height: calc(100vh - 0px);
  object-fit: cover;
}

p.top_links.right {
  left: 44px;
}

.login_form_content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
}
.col-lg-6.col-sm-12 {
  position: relative;
}
.content_section {
  max-width: 620px;
  width: 100%;
}
p.top_links {
  position: absolute;
  left: 40px;
  font-size: 14px;
  font-weight: 400;
  top: 50px;
  z-index: 9;
}
p.top_links span {
  color: #be7637;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.users_btn {
  height: 60px;
  border: 1px solid #be763733 !important;
  max-width: 400px !important;
  width: 100% !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  max-width: 400pxsidebar;
}
.content_section h2 {
  font-size: 36px;
  color: #202620;
  line-height: 44px;
  font-weight: 600;
  margin-bottom: 10px;
}
.content_section h5 {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  color: #202620;
  margin-bottom: 30px;
}

.content_section .form-control {
  width: 100%;
  height: 60px;
  border-radius: 10px;
  background: white;
  border: 1px solid #be763733 !important;
  font-size: 14px;
  color: #5a5a5a;
  margin-bottom: 20px;
}
button.eye-icon.btn.btn-outline-secondary {
  height: 60px;
  border: 1px solid #be763733;
  border-left: none;
  background: white;
}
.inputs_section {
  max-width: 400px;
  margin: auto;
}
p.top_links.back_arrow {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #be7637;
  left: 40px;
  cursor: pointer;
}
.change-passbtn {
  background-color: #be7637;
  border: 1px solid #be7637;
  color: #fff;
}
.change-passbtn:hover {
  background-color: #be7637;
  border: 1px solid #be7637;
}
.forget_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;
  margin: auto;
}
.form-check.form-switch label {
  font-size: 12px;
  line-height: 20px;
  color: #202620;
  margin: 0;
}
.content_section .input-group {
  max-width: 400px !important;
  width: 100%;
  margin: auto;
}
.form-row {
  max-width: 400px !important;
  width: 100%;
  margin: auto;
}
input#flexSwitchCheckDefault {
  width: 40px;
  height: 20px;
}
.form-check.form-switch {
  display: flex;
  align-items: center;
  gap: 5px;
}
.form-row.w-100.login_btn_colum {
  padding-top: 35px;
}
.forget_row p.mb-0 {
  font-size: 14px;
  color: #be7637;
  font-weight: 300;
  line-height: 14px;
  cursor: pointer;
}
button.eye-icon.btn.btn-outline-secondary i {
  color: #20262033;
}

.navigation_row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}
.navigation_row p {
  margin: 0;
  font-size: 13px;
}
.content_section h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  color: #202620;
}
p.Account_text {
  color: #be7637;
}
p.Payment_text {
  color: #2026204d;
}

input#password {
  height: 60px;
}
button.eye-icon.btn.btn-outline-secondary {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: -6px;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #be7637;
}

input:focus + .slider {
  box-shadow: 0 0 1px #be7637;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* profile */
.profile-name {
  border: 1px solid #be763733;
  background-color: #fbf6f1;
  display: flex;
  align-items: center;
  padding: 26px;
  border-radius: 10px;
  gap: 18px;
}
.profile-img img {
  width: 132.807px;
  height: 132.807px;
  flex-shrink: 0;
}
.profile-content {
  display: flex;
  gap: 25px;
}
.profile-contentmain {
  display: flex;
  flex-direction: column;
}
.profile-contentmain h1 {
  color: #202620;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}
.profile-content-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}
.profile-content-icon img {
  height: 16px;
  width: 16px;
}
.profile-content-icon p {
  color: #5a5a5a;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 14px;
}
.message-content-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}
.message-content-icon img {
  height: 20px;
  width: 20px;
}
.message-content-icon p {
  color: #5a5a5a;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 14px;
}
.profile-details {
  border: 1px solid #be763733;
  border-radius: 10px;
  margin-top: 25px;
}
.profile-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #be7637;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 66px;
  padding: 10px 25px;
}
.profile-top h1 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0px;
}
.profile-top button {
  border-radius: 10px;
  border: 1px solid #be763733;
  background: #fff;
  width: 127px;
  height: 42px;
  color: #be7637;
  font-size: 15px;
  font-weight: 600;
  line-height: 14.5px;
}
.profile-details-main {
  padding: 20px;
}
.profile-details-content {
  display: flex;
  width: 100%;
  padding: 5px 5px 5px 5px;
  align-items: center;
}

.first-part {
  flex: 0 0 30%;
}

.second-part {
  flex: 0 0 60%;
}
.first-part p {
  color: #202620;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.second-part p {
  color: #5a5a5a;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}
.first-part span {
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.first-part-span {
  margin-bottom: 0px;
}
.edit-img {
  position: relative;
}
.Profile_img {
  width: 91px;
  height: 91px;
  border-radius: 10px;
  border: 2px solid #fff;
}
.Editicon {
  width: 22px;
  height: 22px;
  position: absolute;
  border-radius: 20px;
  top: 0px;
  left: 70px;
  background: #bd753e;
  border: none;
  cursor: pointer;
}
.second-part input {
  width: 428px;
}
.second-part .form-select {
  width: 428px;
}
.second-part textarea#floatingTextarea2 {
  width: 428px;
}
.edit-content {
  margin-bottom: 10px;
}
.editprofile-main {
  border: 1px solid #be763733;
  margin-top: 20px;
  border-radius: 10px;
}
.editprofile-main .profile-details {
  border: none;
  margin-top: unset;
  border-bottom: 1px solid #be763733;
  border-radius: unset;
}

.editpro-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 20px;
}
.editpro-container .Discard {
  color: #be7637;
  font-size: 15px;
  font-weight: 600;
  line-height: 14.5px;
  border-radius: 10px;
  border: 1px solid rgba(190, 118, 55, 0.2);
  background-color: #fff;
  width: 118px;
  height: 42px;
}
.editpro-container .Update {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 14.5px;
  border-radius: 10px;
  background-color: #be7637;
  border: 1px solid #be7637;
  width: 138px;
  height: 42px;
}
.chagepassword-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1299;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.chagepassword-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 450px;
  height: 475px;
  display: flex;
  align-items: center;
}
.chagepassword-content i.fa-solid.fa-xmark {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #5a5a5a;
  width: 20px;
  height: 20px;
}
.chagepass-popupcontent {
  margin-top: 25px;
}
.chagepass-popupcontent h1 {
  color: #202620;
  font-size: 22px;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
}
.chagepass-popupcontent p {
  color: #5a5a5a;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
}
.chagepass-popupcontent .input-group {
  margin-bottom: 20px;
}
.chagesuccess-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.chagesuccess-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 450px;
  height: 232px;
}

.chagesuccess-contentdiv {
  text-align: center;
  margin: 20px 0;
}

.chagesuccess-content .fa-xmark {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #5a5a5a;
  width: 20px;
  height: 20px;
}
.chagesuccess-contentdiv img {
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
}
.chagesuccess-contentdiv h1 {
  color: #202620;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  line-height: 140%;
}
.logout-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.logout-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 450px;
  height: 359px;
}
.logout-contentdiv {
  text-align: center;
  margin: 20px 0;
}

.logout-content .fa-xmark {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #5a5a5a;
  width: 20px;
  height: 20px;
}
.logout-contentdiv img {
  width: 67.838px;
  height: 73px;
  margin-bottom: 20px;
}
.logout-contentdiv h1 {
  color: #202620;
  font-size: 22px;
  font-weight: 600;
  margin-top: 10px;
}
.logout-contentdiv p {
  color: #5a5a5a;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin: 10px 60px 35px 60px;
}
.logout-btn {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}
.logout-btn .btn1 {
  width: 190px;
  padding: 15px 78px;
  border-radius: 10px;
  border: 1px solid #be7637;
  background: #fff;
  color: #be7637;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logout-btn .btn2 {
  width: 190px;
  padding: 15px 78px;
  border-radius: 10px;
  border: 1px solid #be7637;
  background: #be7637;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.changepass_section {
  max-width: 100%;
  margin: 0px auto;
  min-height: calc(100vh - 0px);
  position: relative;
  max-height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
p.top_links.back_arrow {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #be7637;
  left: 40px;
  cursor: pointer;
}
.changepass_form_content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
}
.content_section {
  max-width: 620px;
  width: 100%;
}

.content_section h2 {
  font-size: 36px;
  color: #202620;
  line-height: 44px;
  font-weight: 600;
  margin-bottom: 15px;
}
.content_section h5 {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #202620;
  margin-bottom: 30px;
}
.form-row {
  max-width: 400px !important;
  width: 100%;
  margin: auto;
}

.content_section .input-group {
  max-width: 400px !important;
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
}
.users_btn {
  height: 60px;
  border: 1px solid #be763733 !important;
  max-width: 400px !important;
  width: 100% !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  max-width: 400pxsidebar;
}
.change-passbtn {
  background-color: #be7637;
  border: 1px solid #be7637;
  color: #fff;
}
.form-row {
  max-width: 400px !important;
  width: 100%;
  margin: auto;
}
.content_section .form-control {
  background: #fff;
  border: 1px solid #be763733!important;
  border-radius: 10px;
  color: #5a5a5a;
  font-size: 14px;
  height: 60px;
  margin-bottom: 20px;
  width: 100%;
}
button.eye-icon.btn.btn-outline-secondary i {
  color: #20262033;
}
button.eye-icon.btn.btn-outline-secondary {
  background: #fff;
  border: 1px solid #be763733;
  border-left: none;
  height: 60px;
}
button.eye-icon.btn.btn-outline-secondary {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.col-lg-7.col-sm-12, .login_section {
  position: relative;
}
p.top_links {
  font-size: 14px;
  font-weight: 400;
  left: 40px;
  position: absolute;
  top: 50px;
  z-index: 9;
}